import axios from './index'

export default {
  login (userData) {
      let data = {
          grant_type: 'password',
          client_id: process.env.MIX_API_CLIENT_ID,
          client_secret: process.env.MIX_API_CLIENT_SECRET,
          username: userData.email,
          password: userData.password
      }
    return axios.post('/oauth/token', data)
  },
  userInfo () {
    return axios.get('/api/user',{
      headers: {
        'Authorization': `Bearer ${window.localStorage.getItem("access_token")}`
      }
    })
  },
  register (userData) {
    return axios.post('/api/register', userData)
  },
  forgotPassword (userData) {
    return axios.post('/api/forgot-password', userData)
  },
  resetPassword (userData) {
    return axios.post('/api/reset-password', userData)
  }
}
