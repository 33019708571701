import Vue from 'vue'
import VueRouter from 'vue-router'
import Store from '../store/index'
import auth from "../services/auth"; 
import VueSplide from '@splidejs/vue-splide';

const BlankLayout = () => import('../layouts/BlankLayout')
const AdminLayout = () => import('../layouts/AdminLayout')
const EmptyLayout = () => import('../layouts/EmptyLayout')
/*const AuthLayout = () => import('../layouts/AuthLayout') need to fix this somehow */
const Login = () => import('../views/website/Login')
const Dashboard = () => import('../views/website/Dashboard')
const ForgotPassword = () => import('../views/website/ForgotPassword')
const ResetPassword = () => import('../views/website/ResetPassword')

const WebsitePriceAuditList  = () => import('../views/website/price-audit/List')
const WebsitePCTrackerList  = () => import('../views/website/pctracker/List')
const WebsiteSellInOutList  = () => import('../views/website/sell-in-out/List')
const WebsiteSellInSellOutList  = () => import('../views/website/sell-in-out/SpecialList')
const WebsiteStockCountByStoreList = () => import('../views/website/stock-count-by-store/List')
const WebsiteMarketingActivityList = () => import('../views/website/marketing-activity/List')
const WebsiteNumericDistributionList = () => import('../views/website/numeric-distribution/List')
const WebsiteStoreList = () => import('../views/website/store/List')
const WebsiteOrderList = () => import('../views/website/order/List')
const WebsiteMerchandiserVisitList = () => import('../views/website/MerchandiserVisit')
const WebsiteOpportunities = () => import('../views/website/Opportunities')
const WebsiteDownloadReport = () => import('../views/website/DownloadReport')
const WebsiteProductExpiryTrackerList = () => import('../views/website/product-expiry-tracker/List')
const WebsiteOutOfStockList  = () => import('../views/website/out-of-stock/List')

const AdminDashboard = () => import('../views/admin/Dashboard')
const AdminCompanyList = () => import('../views/admin/company/List')
const AdminCompanyCreate = () => import('../views/admin/company/Create')
const AdminCompanyEdit = () => import('../views/admin/company/Edit')
const AdminUserList = () => import('../views/admin/users/List')
const AdminUserCreate = () => import('../views/admin/users/Create')
const AdminUserEdit = () => import('../views/admin/users/Edit')
const AdminStoreList = () => import('../views/admin/stores/List')
const AdminStoreCreate = () => import('../views/admin/stores/Create')
const AdminStoreEdit = () => import('../views/admin/stores/Edit')
const AdminProductList = () => import('../views/admin/products/List')
const AdminProductCreate = () => import('../views/admin/products/Create')
const AdminProductEdit = () => import('../views/admin/products/Edit')
const AdminOrderList = () => import('../views/admin/orders/List')
const AdminOrderCreate = () => import('../views/admin/orders/Create')
const AdminOrderEdit = () => import('../views/admin/orders/Edit')
const AdminOutOfStockList = () => import('../views/admin/out_of_stock/List')
const AdminOutOfStockCreate = () => import('../views/admin/out_of_stock/Create')
const AdminOutOfStockEdit = () => import('../views/admin/out_of_stock/Edit')
const AdminPerformanceList = () => import('../views/admin/performance/List')
const AdminPerformanceCreate = () => import('../views/admin/performance/Create')
const AdminPerformanceEdit = () => import('../views/admin/performance/Edit')
const AdminBusinessOverviewList = () => import('../views/admin/business-overview/List')
const AdminBusinessOverviewCreate = () => import('../views/admin/business-overview/Create')
const AdminBusinessOverviewEdit = () => import('../views/admin/business-overview/Edit')
const AdminPriceAuditList = () => import('../views/admin/price-audit/List')
const AdminPriceAuditCreate = () => import('../views/admin/price-audit/Create')
const AdminPriceAuditEdit = () => import('../views/admin/price-audit/Edit')
const AdminStoreStockList = () => import('../views/admin/store-stock/List')
const AdminStoreStockCreate = () => import('../views/admin/store-stock/Create')
const AdminStoreStockEdit = () => import('../views/admin/store-stock/Edit')
const AdminPCTrackerList = () => import('../views/admin/pctracker/List')
const AdminPCTrackerCreate = () => import('../views/admin/pctracker/Create')
const AdminPCTrackerEdit = () => import('../views/admin/pctracker/Edit')
const AdminPCTrackerAddImage = () => import('../views/admin/pctracker/AddImage')

const AdminSellInOutList = () => import('../views/admin/sell-in-out/List')
const AdminSellInOutCreate = () => import('../views/admin/sell-in-out/Create')
const AdminSellInOutEdit = () => import('../views/admin/sell-in-out/Edit')

const AdminMarketingActivityList = () => import('../views/admin/marketing-activity/List')
const AdminMarketingActivityCreate = () => import('../views/admin/marketing-activity/Create')
const AdminMarketingActivityEdit = () => import('../views/admin/marketing-activity/Edit')


const AdminNumericDistributionList = () => import('../views/admin/numeric-distribution/List')
const AdminNumericDistributionCreate = () => import('../views/admin/numeric-distribution/Create')
const AdminNumericDistributionEdit = () => import('../views/admin/numeric-distribution/Edit')

const AdminLocationList = () => import('../views/admin/location/List')
const AdminLocationCreate = () => import('../views/admin/location/Create')
const AdminLocationEdit = () => import('../views/admin/location/Edit')

const AdminParishList = () => import('../views/admin/parish/List')
const AdminParishCreate = () => import('../views/admin/parish/Create')
const AdminParishEdit = () => import('../views/admin/parish/Edit')

const AdminNotificationList = () => import('../views/admin/notification/List')
const AdminNotificationCreate = () => import('../views/admin/notification/Create')
const AdminBlogList = () => import('../views/admin/blog/List')
const AdminBlogCreate = () => import('../views/admin/blog/Create')
const AdminBlogEdit = () => import('../views/admin/blog/Edit')

const AdminServiceList = () => import('../views/admin/service/List')
const AdminServiceCreate = () => import('../views/admin/service/Create')
const AdminServiceEdit = () => import('../views/admin/service/Edit')

const AdminPageList = () => import('../views/admin/page/List')
const AdminPageCreate = () => import('../views/admin/page/Create')
const AdminPageEdit = () => import('../views/admin/page/Edit')

const AdminSettingList = () => import('../views/admin/setting/List')
const AdminSettingCreate = () => import('../views/admin/setting/Create')
const AdminSettingEdit = () => import('../views/admin/setting/Edit')


const AdminCompetitorProductList = () => import('../views/admin/competitor-product/List')
const AdminCompetitorProductCreate = () => import('../views/admin/competitor-product/Create')
const AdminCompetitorProductEdit = () => import('../views/admin/competitor-product/Edit')

const AdminContactRequestList = () => import('../views/admin/contact-request/List')

const AdminProductExpiryTrackerList = () => import('../views/admin/product-expiry-tracker/List')

Vue.use(VueRouter)
Vue.use(VueSplide);


const authRoutes = (prop) => [
  {
    path: '/',
    name: prop + '.login',
    meta: { auth: false, name: 'Login', layout: 'website' },
    component: Login
  }
]

const downloadRoutes = (prop) => [
  {
    path: '/',
    name: prop + '.download',
    meta: { auth: false, name: 'Download Report', layout: 'website' },
    component: WebsiteDownloadReport
  }
]

const websiteRoutes = (prop) => [
  {
    path: '/',
    name: prop + '.dashboard',
    meta: { auth: true, name: 'Dashboard', layout: 'website' },
    component: Dashboard
  },
  {
    path: '/price-audit',
    name: prop + '.dashboard',
    meta: { auth: true, name: 'Price Audit Data', layout: 'website' },
    component: WebsitePriceAuditList
  },
  {
    path: '/planogram-compliance-tracker',
    name: prop + '.planogram-compliance-tracker',
    meta: { auth: true, name: 'Planogram Compliance Tracker', layout: 'website' },
    component: WebsitePCTrackerList
  },
  {
    path: '/sell-in-out',
    name: prop + '.sell-in-out',
    meta: { auth: true, name: 'Sell In Sell Out', layout: 'website' },
    component: WebsiteSellInOutList
  },
  {
    path: '/sell-in-sell-out',
    name: prop + '.sell-in-sell-out',
    meta: { auth: true, name: 'Sell In Sell Out', layout: 'website' },
    component: WebsiteSellInSellOutList
  },
  {
    path: '/stock-count-by-store',
    name: prop + '.stock-count-by-store',
    meta: { auth: true, name: 'Stock count by store', layout: 'website' },
    component: WebsiteStockCountByStoreList
  },
  {
    path: '/marketing-activity',
    name: prop + '.marketing-activity',
    meta: { auth: true, name: 'Marketing Activity', layout: 'website' },
    component: WebsiteMarketingActivityList
  },
  {
    path: '/numeric-distribution',
    name: prop + '.numeric-distribution',
    meta: { auth: true, name: 'Numeric Distribution', layout: 'website' },
    component: WebsiteNumericDistributionList
  },
  {
    path: '/store-list',
    name: prop + '.store-list',
    meta: { auth: true, name: 'Store List', layout: 'website' },
    component: WebsiteStoreList
  },
  {
    path: '/order-list',
    name: prop + '.order-list',
    meta: { auth: true, name: 'Order List', layout: 'website' },
    component: WebsiteOrderList
  },
  {
    path: '/merchandiser-visit',
    name: prop + '.merchandiser-visit',
    meta: { auth: true, name: 'Merchandiser Visit List', layout: 'website' },
    component: WebsiteMerchandiserVisitList
  },
  {
    path: '/product-expiry-tracker',
    name: prop + '.product-expiry-tracker',
    meta: { auth: true, name: 'Product Expiry Tracker', layout: 'website' },
    component: WebsiteProductExpiryTrackerList
  },
  {
    path: '/out-of-stock',
    name: prop + '.out-of-stock',
    meta: { auth: true, name: 'Out Of Stock Data', layout: 'website' },
    component: WebsiteOutOfStockList
  },
  {
    path: '/opportunities',
    name: prop + '.opportunities',
    meta: { auth: true, name: 'Opportunities', layout: 'website' },
    component: WebsiteOpportunities
  },
  {
    path: 'notifications',
    name: 'notifications',
    meta: { auth: true, name: 'Notification', layout: 'website' },
    component: AdminNotificationList
  },
  {
    path: 'notifications/create',
    name: prop + '.notifications.create',
    meta: { auth: true, name: 'Create Notification', layout: 'website' },
    component: AdminNotificationCreate
  }
]

const adminRoutes = (prop) => [
  {
    path: '/',
    name: 'website.dashboard',
    meta: { auth: true, name: 'Dashboard', layout: 'admin' },
    component: AdminDashboard
  },
  {
    path: 'users',
    name: prop + '.users',
    meta: { auth: true, name: 'Users', layout: 'admin' },
    component: AdminUserList
  },
  {
    path: 'companyname',
    name: prop + '.companyname',
    meta: {auth:true, name: 'Company Name', layout: 'admin'},
    component: AdminCompanyList
  },
  {
    path: 'companyname/create',
    name: prop + '.companyname.create',
    meta: {auth:true, name: 'Company Creator', layout: 'admin'},
    component: AdminCompanyCreate
  },
  {
    path: 'companyname/edit/:id/:name',
    name: prop + '.companyname.edit',
    meta: {auth:true, name: 'Company Editor', layout: 'admin'},
    component: AdminCompanyEdit
  },
  {
    path: 'users/create',
    name: prop + '.users.create',
    meta: { auth: true, name: 'Create User', layout: 'admin' },
    component: AdminUserCreate
  },
  {
    path: 'users/:id/edit',
    name: prop + '.users.edit',
    meta: { auth: true, name: 'Edit User', layout: 'admin' },
    component: AdminUserEdit
  },
  {
    path: 'stores',
    name: prop + '.stores',
    meta: { auth: true, name: 'Stores', layout: 'admin' },
    component: AdminStoreList
  },
  {
    path: 'stores/create',
    name: prop + '.stores.create',
    meta: { auth: true, name: 'Create Store', layout: 'admin' },
    component: AdminStoreCreate
  },
  {
    path: 'stores/:id/edit',
    name: prop + '.stores.edit',
    meta: { auth: true, name: 'Edit Store', layout: 'admin' },
    component: AdminStoreEdit
  },
  {
    path: 'products',
    name: prop + '.products',
    meta: { auth: true, name: 'Products', layout: 'admin' },
    component: AdminProductList
  },
  {
    path: 'products/create',
    name: prop + '.products.create',
    meta: { auth: true, name: 'Create Product', layout: 'admin' },
    component: AdminProductCreate
  },
  {
    path: 'products/:id/edit',
    name: prop + '.products.edit',
    meta: { auth: true, name: 'Edit Product', layout: 'admin' },
    component: AdminProductEdit
  },
  {
    path: 'orders',
    name: prop + '.orders',
    meta: { auth: true, name: 'Orders', layout: 'admin' },
    component: AdminOrderList
  },
  {
    path: 'orders/create',
    name: prop + '.orders.create',
    meta: { auth: true, name: 'Create Order', layout: 'admin' },
    component: AdminOrderCreate
  },
  {
    path: 'orders/:id/edit',
    name: prop + '.orders.edit',
    meta: { auth: true, name: 'Edit Order', layout: 'admin' },
    component: AdminOrderEdit
  },
  {
    path: 'out-of-stock',
    name: prop + '.out-of-stock',
    meta: { auth: true, name: 'Out of stock', layout: 'admin' },
    component: AdminOutOfStockList
  },
  {
    path: 'out-of-stock/create',
    name: prop + '.out-of-stock.create',
    meta: { auth: true, name: 'Create Out of stock', layout: 'admin' },
    component: AdminOutOfStockCreate
  },
  {
    path: 'out-of-stock/:id/edit',
    name: prop + '.out-of-stock.edit',
    meta: { auth: true, name: 'Edit Out of stock', layout: 'admin' },
    component: AdminOutOfStockEdit
  },
  {
    path: 'performance-by-sell',
    name: prop + '.performance-by-sell',
    meta: { auth: true, name: 'performance by sell', layout: 'admin' },
    component: AdminPerformanceList
  },
  {
    path: 'performance-by-sell/create',
    name: prop + '.performance-by-sell.create',
    meta: { auth: true, name: 'Create Performance by sell', layout: 'admin' },
    component: AdminPerformanceCreate
  },
  {
    path: 'performance-by-sell/:id/edit',
    name: prop + '.performance-by-sell.edit',
    meta: { auth: true, name: 'Edit Performance by sell', layout: 'admin' },
    component: AdminPerformanceEdit
  },
  {
    path: 'business-overview',
    name: prop + '.business-overview',
    meta: { auth: true, name: 'Business overview', layout: 'admin' },
    component: AdminBusinessOverviewList
  },
  {
    path: 'business-overview/create',
    name: prop + '.business-overview.create',
    meta: { auth: true, name: 'Create Business overview', layout: 'admin' },
    component: AdminBusinessOverviewCreate
  },
  {
    path: 'business-overview/:id/edit',
    name: prop + '.business-overview.edit',
    meta: { auth: true, name: 'Edit Business overview', layout: 'admin' },
    component: AdminBusinessOverviewEdit
  },
  {
    path: 'price-audit',
    name: prop + '.price-audit',
    meta: { auth: true, name: 'Price Audit', layout: 'admin' },
    component: AdminPriceAuditList
  },
  {
    path: 'price-audit/create',
    name: prop + '.price-audit.create',
    meta: { auth: true, name: 'Create Price Audit', layout: 'admin' },
    component: AdminPriceAuditCreate
  },
  {
    path: 'price-audit/:id/edit',
    name: prop + '.price-audit.edit',
    meta: { auth: true, name: 'Edit Price Audit', layout: 'admin' },
    component: AdminPriceAuditEdit
  },
  {
    path: 'stock-count-by-store',
    name: prop + '.stock-count-by-store',
    meta: { auth: true, name: 'Store Stock', layout: 'admin' },
    component: AdminStoreStockList
  },
  {
    path: 'stock-count-by-store/create',
    name: prop + '.stock-count-by-store.create',
    meta: { auth: true, name: 'Create Store Stock', layout: 'admin' },
    component: AdminStoreStockCreate
  },
  {
    path: 'stock-count-by-store/:id/edit',
    name: prop + '.stock-count-by-store.edit',
    meta: { auth: true, name: 'Edit Store Stock', layout: 'admin' },
    component: AdminStoreStockEdit
  },
  {
    path: 'planogram-compliance-tracker',
    name: prop + '.planogram-compliance-tracker',
    meta: { auth: true, name: 'Planogram Compliance Tracker', layout: 'admin' },
    component: AdminPCTrackerList
  },
  {
    path: 'planogram-compliance-tracker/create',
    name: prop + '.planogram-compliance-tracker.create',
    meta: { auth: true, name: 'Create Planogram Compliance Tracker', layout: 'admin' },
    component: AdminPCTrackerCreate
  },
  {
    path: 'planogram-compliance-tracker/:id/edit',
    name: prop + '.planogram-compliance-tracker.edit',
    meta: { auth: true, name: 'Edit Planogram Compliance Tracker', layout: 'admin' },
    component: AdminPCTrackerEdit
  },
  {
    path: 'planogram-compliance-tracker/add-image',
    name: prop + '.planogram-compliance-tracker.add-image',
    meta: { auth: true, name: 'Create Planogram Compliance Tracker Image', layout: 'admin' },
    component: AdminPCTrackerAddImage
  },
  {
    path: 'sell-in-out',
    name: prop + '.sell-in-out',
    meta: { auth: true, name: 'Sell In Sell Out', layout: 'admin' },
    component: AdminSellInOutList
  },
  {
    path: 'sell-in-out/create',
    name: prop + '.sell-in-out.create',
    meta: { auth: true, name: 'Create Sell In Sell Out', layout: 'admin' },
    component: AdminSellInOutCreate
  },
  {
    path: 'sell-in-out/:id/edit',
    name: prop + '.sell-in-out.edit',
    meta: { auth: true, name: 'Edit Sell In Sell Out', layout: 'admin' },
    component: AdminSellInOutEdit
  },
  {
    path: 'marketing-activity',
    name: prop + '.marketing-activity',
    meta: { auth: true, name: 'Marketing Activity', layout: 'admin' },
    component: AdminMarketingActivityList
  },
  {
    path: 'marketing-activity/create',
    name: prop + '.marketing-activity.create',
    meta: { auth: true, name: 'Create Marketing Activity', layout: 'admin' },
    component: AdminMarketingActivityCreate
  },
  {
    path: 'marketing-activity/:id/edit',
    name: prop + '.marketing-activity.edit',
    meta: { auth: true, name: 'Edit Marketing Activity', layout: 'admin' },
    component: AdminMarketingActivityEdit
  },
  {
    path: 'numeric-distribution',
    name: prop + '.numeric-distribution',
    meta: { auth: true, name: 'Numeric Distribution', layout: 'admin' },
    component: AdminNumericDistributionList
  },
  {
    path: 'numeric-distribution/create',
    name: prop + '.numeric-distribution.create',
    meta: { auth: true, name: 'Create Numeric Distribution', layout: 'admin' },
    component: AdminNumericDistributionCreate
  },
  {
    path: 'numeric-distribution/:id/edit',
    name: prop + '.numeric-distribution.edit',
    meta: { auth: true, name: 'Edit Numeric Distribution', layout: 'admin' },
    component: AdminNumericDistributionEdit
  },
  {
    path: 'location',
    name: prop + '.location',
    meta: { auth: true, name: 'Location', layout: 'admin' },
    component: AdminLocationList
  },
  {
    path: 'location/create',
    name: prop + '.location.create',
    meta: { auth: true, name: 'Create Location', layout: 'admin' },
    component: AdminLocationCreate
  },
  {
    path: 'location/:id/edit',
    name: prop + '.location.edit',
    meta: { auth: true, name: 'Edit Location', layout: 'admin' },
    component: AdminLocationEdit
  },
  {
    path: 'parish',
    name: prop + '.parish',
    meta: { auth: true, name: 'Parish', layout: 'admin' },
    component: AdminParishList
  },
  {
    path: 'parish/create',
    name: prop + '.parish.create',
    meta: { auth: true, name: 'Create Parish', layout: 'admin' },
    component: AdminParishCreate
  },
  {
    path: 'parish/:id/edit',
    name: prop + '.parish.edit',
    meta: { auth: true, name: 'Edit Parish', layout: 'admin' },
    component: AdminParishEdit
  },
  {
    path: 'notifications',
    name: 'notifications',
    meta: { auth: true, name: 'Notification', layout: 'admin' },
    component: AdminNotificationList
  },
  {
    path: 'notifications/create',
    name: prop + '.notifications.create',
    meta: { auth: true, name: 'Create Notification', layout: 'admin' },
    component: AdminNotificationCreate
  },
  {
    path: 'blog',
    name: prop + '.blog',
    meta: { auth: true, name: 'Blog', layout: 'admin' },
    component: AdminBlogList
  },
  {
    path: 'blog/create',
    name: prop + '.blog.create',
    meta: { auth: true, name: 'Create Blog', layout: 'admin' },
    component: AdminBlogCreate
  },
  {
    path: 'blog/:id/edit',
    name: prop + '.blog.edit',
    meta: { auth: true, name: 'Edit Blog', layout: 'admin' },
    component: AdminBlogEdit
  },
  {
    path: 'service',
    name: prop + '.service',
    meta: { auth: true, name: 'Service', layout: 'admin' },
    component: AdminServiceList
  },
  {
    path: 'service/create',
    name: prop + '.service.create',
    meta: { auth: true, name: 'Create Service', layout: 'admin' },
    component: AdminServiceCreate
  },
  {
    path: 'service/:id/edit',
    name: prop + '.service.edit',
    meta: { auth: true, name: 'Edit Service', layout: 'admin' },
    component: AdminServiceEdit
  },
  {
    path: 'contact-request',
    name: prop + '.contact-request.edit',
    meta: { auth: true, name: 'Contact Request', layout: 'admin' },
    component: AdminContactRequestList
  },
  {
    path: 'page',
    name: prop + '.page',
    meta: { auth: true, name: 'Page', layout: 'admin' },
    component: AdminPageList
  },
  {
    path: 'page/create',
    name: prop + '.page.create',
    meta: { auth: true, name: 'Create Page', layout: 'admin' },
    component: AdminPageCreate
  },
  {
    path: 'page/:id/edit',
    name: prop + '.page.edit',
    meta: { auth: true, name: 'Edit Page', layout: 'admin' },
    component: AdminPageEdit
  },
  {
    path: 'product-expiry-tracker',
    name: prop + '.product-expiry-tracker',
    meta: { auth: true, name: 'Product Expiry Tracker', layout: 'admin' },
    component: AdminProductExpiryTrackerList
  },
  {
    path: 'setting',
    name: prop + '.setting',
    meta: { auth: true, name: 'Setting', layout: 'admin' },
    component: AdminSettingList
  },
  {
    path: 'setting/create',
    name: prop + '.setting.create',
    meta: { auth: true, name: 'Create Setting', layout: 'admin' },
    component: AdminSettingCreate
  },
  {
    path: 'setting/:id/edit',
    name: prop + '.setting.edit',
    meta: { auth: true, name: 'Edit Setting', layout: 'admin' },
    component: AdminSettingEdit
  },
  {
    path: 'competitor-products',
    name: prop + '.competitor-products',
    meta: { auth: true, name: 'Competitor Products', layout: 'admin' },
    component: AdminCompetitorProductList
  },
  {
    path: 'competitor-products/create',
    name: prop + '.competitor-products.create',
    meta: { auth: true, name: 'Create competitor product', layout: 'admin' },
    component: AdminCompetitorProductCreate
  },
  {
    path: 'competitor-products/:id/edit',
    name: prop + '.competitor-products.edit',
    meta: { auth: true, name: 'Edit competitor product', layout: 'admin' },
    component: AdminCompetitorProductEdit
  }
]


const host = window.location.host;
const parts = host.split('.');
var routes = null;

if(parts[0] == "admin")  {
  routes = [
    {
      path: '/login',
      name: 'website.login',
      component: Login,
      meta: { auth: false }
    },
    {
      path: '/forgot-password',
      name: 'website.forgotPassword',
      component: ForgotPassword,
      meta: { auth: false }
    },
    {
      path: '/reset-password',
      name: 'website.resetPassword',
      component: ResetPassword,
      meta: { auth: false }
    },
    {
      path: '/',
      name: 'admin',
      component: AdminLayout,
      meta: { auth: true },
      children: adminRoutes('admin')
    }
  ];
}else{

  routes = [
    {
      path: '/login',
      name: 'website.login',
      component: Login,
      meta: { auth: false }
    },
    {
      path: '/forgot-password',
      name: 'website.forgotPassword',
      component: ForgotPassword,
      meta: { auth: false }
    },
    {
      path: '/reset-password',
      name: 'website.resetPassword',
      component: ResetPassword,
      meta: { auth: false }
    },
    {
      path: '/download',
      name: 'download',
      component: EmptyLayout,
      meta: { auth: true , layout: 'website'},
      //children: downloadRoutes
    },
    {
      path: '/',
      name: 'site',
      component: BlankLayout,
      meta: { auth: true },
      children: websiteRoutes('website')
    }
  ];
}

/*const routes = [
  {
    path: '/',
    name: 'site',
    component: BlankLayout,
    meta: { auth: true },
    children: websiteRoutes('website')
  },
  {
    path: '/login',
    name: 'website.login',
    component: Login,
    meta: { auth: false }
  },
  {
    path: '/admin',
    name: 'admin',
    component: AdminLayout,
    meta: { auth: true },
    children: adminRoutes('admin')
  }
]*/

const router = new VueRouter({
  mode: 'history',
  base: process.env.MIX_SENTRY_DSN_INDEX,
  routes
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/admin-login', '/forgot-password', '/reset-password']
  const downloadPages = ['/download'];

  if (publicPages.includes(to.path)) {
      localStorage.removeItem('user')
      localStorage.removeItem('access_token')
  }


  const authRequired = !publicPages.includes(to.path)
  const loggedIn = localStorage.getItem('access_token')

  //console.log("Action verification started" , [ {"authRequired" : authRequired, "loggedIn" : loggedIn }]);
  //console.log("TEST",downloadPages.includes(to.path))
  if (downloadPages.includes(to.path) && loggedIn == null) {
      localStorage.setItem(
      "ask_reffer",
      to.fullPath
     )
  }

  if (to.meta.auth) {
    if (authRequired && loggedIn === null) {
      return next('/login')
    }else{
      auth.userInfo(loggedIn).then(response => {
          if(response.status == 200) {
            var userdata = response.data;
            if(userdata.role == "user" || (userdata.role == "user" && userdata.access_privilage == 0)) {
               if(to.meta.layout != "website") {
                  return next('/404')
               }

               if(to.meta.layout == "website" && userdata.access_privilage == 0) {
                  return next('/login');
               }
            }
            Store.dispatch('Setting/authUserAction', {
              auth: true,
              authType: "token",
              user: {
                id: userdata.id,
                name: userdata.name,
                email: userdata.email
              }
            })

            const httpReffer = localStorage.getItem('ask_reffer');
            if(httpReffer != '' && httpReffer != null) {
               localStorage.removeItem('ask_reffer');
               return next(httpReffer);
            }

          }else{
            return next('/login');
          }
      })
      .finally((response) => {
          //console.log(response);
      });
    }
  }

  next()
})

export default router