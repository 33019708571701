import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
  parishes: {
    data : [],
    pagination:{}
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
