import Vue from 'vue'
import Vuex from 'vuex'
import Setting from './Setting/index'
import User from './User/index'
import Store from './Store/index'
import Product from './Product/index'
import Order from './Order/index'
import OutOfStock from './OutOfStock/index'
import Performance from './Performance/index'
import BusinessOverview from './BusinessOverview/index'
import PriceAudit from './PriceAudit/index'
import StoreStock from './StoreStock/index'
import PCTracker from './PCTracker/index'
import SellInOut from './SellInOut/index'
import MarketingActivity from './MarketingActivity/index'
import NumericDistribution from './NumericDistribution/index'
import Location from './Location/index'
import Parish from './Parish/index'
import Notification from './Notification/index'
import Blog from './Blog/index'
import Service from './Service/index'
import Page from './Page/index'
import ProductExpiryTracker from './ProductExpiryTracker/index'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    Setting,
    User,
    Store,
    Product,
    Order,
    OutOfStock,
    Performance,
    BusinessOverview,
    PriceAudit,
    StoreStock,
    PCTracker,
    SellInOut,
    MarketingActivity,
    NumericDistribution,
    Location,
    Parish,
    Notification,
    Service,
    Blog,
    Page,
    ProductExpiryTracker
  },
  state: {
  },
  mutations: {
  },
  actions: {
  },
  getters: {
  },
  strict: debug
})
